import React from 'react';
import Box from 'components/box';
import Layout from 'components/layout';

class NotFound extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined' && window.location) {
      window.location.replace('https://sadforever.lauvsongs.com');
    }
  }

  render() {
    return (
      <Layout>
        <Box>Not found.</Box>
      </Layout>
    );
  }
}

export default NotFound;
